<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('monthlyAllowance.employeeName')
                }}</label>
                <div>
                    <Select
                        v-model="model.employee_id"
                        filterable
                        :remote-method="loadEmployee"
                        :clearable="true"
                        :class="{
                            'is-invalid': errors.has('employee_id')
                        }"
                    >
                        <Option
                            v-for="(employeeProfile, index) in employeeProfiles"
                            :value="employeeProfile.employee_id"
                            :key="index"
                            :label="employeeProfile.employee_name_en"
                            >{{ employeeProfile.employee_name_en }}|{{
                                employeeProfile.employee_name_kh
                            }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_id')"
                    >
                        {{ errors.first('employee_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.allowance')
                    }}</label>
                    <div>
                        <Select
                            v-model="model.allowance_id"
                            :class="{
                                'is-invalid': errors.has('allowance_id')
                            }"
                        >
                            <Option
                                v-for="(payrollItemAllowance,
                                index) in payrollItemAllowances"
                                :value="payrollItemAllowance.allowance_id"
                                :key="index"
                                :label="payrollItemAllowance.allowance_item"
                                >{{ payrollItemAllowance.allowance_item }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('allowance_id')"
                        >
                            {{ errors.first('allowance_id') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.allowanceAmount')
                    }}</label>
                    <div class="tw-whitespace-nowrap">
                        <Poptip trigger="focus">
                            <Input
                                v-model.number="model.allowance"
                                type="number"
                                :placeholder="
                                    $t('monthlyAllowance.enterNumber')
                                "
                                style="width: 179%"
                                ref="inc_bonus"
                                :class="{
                                    'is-invalid': errors.has('allowance')
                                }"
                            >
                            </Input>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('allowance')"
                            >
                                {{ errors.first('allowance') }}
                            </div>
                            <div slot="content">
                                {{
                                    model.allowance
                                        ? formatNumber(model.allowance)
                                        : $t('monthlyAllowance.enterNumber')
                                }}
                            </div>
                        </Poptip>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.cycleYear')
                    }}</label>
                    <date-Picker
                        :value="model.cycle_year"
                        type="year"
                        format="yyyy"
                        placeholder="Select Year"
                        style="width:320px"
                        @on-change="cycleYearChange"
                        :class="{
                            'is-invalid': errors.has('cycle_year')
                        }"
                    >
                    </date-Picker>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('cycle_year')"
                    >
                        {{ errors.first('cycle_year') }}
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.cycleMonth')
                    }}</label>
                    <div>
                        <date-Picker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width:320px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label>{{ $t('monthlyAllowance.description') }}</label>
                    <Input
                        v-model="model.description"
                        type="textarea"
                        :rows="5"
                        placeholder="Enter something..."
                    />
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        class="btn-gray"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { isEmpty } from 'lodash'

export default {
    name: 'monthly-allowance-create',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                employee_id: null,
                allowance_id: null,
                allowance: null,
                cycle_year: null,
                cycle_month: null,
                payroll_cycle_id: null,
                description: null
            }
        }
    },
    computed: {
        ...mapState('payroll/monthlyAllowance', ['edit_data', 'allowance']),
        ...mapGetters(['formatNumber']),

        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        employeeProfiles () {
            return this.$store.state.payroll.monthlyAllowance.employeeProfiles
        },
        payrollItemAllowances () {
            return this.$store.state.payroll.monthlyAllowance
                .payrollItemAllowances
        }
    },
    methods: {
        ...mapActions('payroll/monthlyAllowance', [
            'getEmployeeProfile',
            'getPayrollItemAllowance'
        ]),
        fetchResource () {
            this.loading = true
            this.getEmployeeProfile()
            this.getPayrollItemAllowance()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),

        cycleYearChange (value) {
            this.model.cycle_year = value
        },
        cycleMonthChange (value) {
            this.model.cycle_month = value
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/monthlyAllowance/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('ceacel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/monthlyAllowance/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.allowance_id = null
            this.model.allowance = null
            this.model.cycle_year = null
            this.model.cycle_month = null
            this.model.description = null
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployeeProfile({
                    search: this.edit_data.employee_profile.employee_name_en
                })
                this.model.employee_id = this.edit_data.employee_id
                this.model.allowance_id = this.edit_data.allowance_id
                this.model.allowance = this.edit_data.allowance
                this.model.cycle_year = this.edit_data.cycle_year
                this.model.cycle_month = this.edit_data.cycle_month
                this.model.description = this.edit_data.description
            }
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/monthlyAllowance/update', {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY ALLOWANCE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
